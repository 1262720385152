import React from 'react';
import {Table, message} from "antd";
import {AssessmentsColumns} from "./columns";
import axios from "axios";
import fileDownload from "js-file-download";
import {withRouter} from "react-router";

class Assessments extends React.Component {

    state = {
        assessments: null,
        loading: false,
        columns: null,
    };

    componentDidMount() {
        this.loadAssessments();
        this.setState({ columns: AssessmentsColumns((id, code) => this.onDownload(id, code), (id) => this.onDelete(id)) })
    }

    loadAssessments() {
        this.setState({ loading: true })
        axios.get('/test-results')
            .then(({ data: assessments }) => {
                let result = assessments.slice();
                assessments.forEach((e, i) => { result[i].is_genfi_participant = e.genfi_uri ? 'YES' : 'NO' });
                this.setState({ assessments: result });
            })
            .finally(() => this.setState({ loading: false }))
    }

    onDownload(id, code) {
        const hide = message.loading('Downloading report...', 0);
        axios({
            url: `/test-results/excel/${id}`,
            method: 'GET',
            responseType: 'arraybuffer',
        }).then(response => {
            hide();
            fileDownload(response.data, `${code}.xlsx`);
        })
    }

    onDelete(id) {
        this.setState({ loading: true })
        axios.delete(`/test-results/${id}`)
            .then(() => {
                this.setState({ assessments: this.state.assessments.filter((a) => id !== a.id) })
            })
            .finally(() => this.setState({ loading: false }));
    }

    render() {
        const { assessments, columns, loading } = this.state;
        return (
            <div className="assessments">
                <div className="page-title">Assessment Results</div>
                <Table
                    loading={loading}
                    columns={columns}
                    rowKey="id"
                    dataSource={assessments}/>
            </div>
        );
    }
}

export default withRouter(Assessments);