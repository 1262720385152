import React from 'react';
import {withRouter} from "react-router";
import {SecondFactorForm} from "../../components/secondFactorForm";
import axios from "axios";
import {AuthService} from "../../authService";

class SecondFactor extends React.Component {

    state = {

    };

    handleAuthentication = ({code}) => {
        this.setState({ loading: true });
        axios.post('/users/second-factor', { code })
            .then(({data}) => AuthService.SetupFullTokens(data, this.props.history))
            .catch(({ response: { data } }) => this.setState({ error: data, loading: false }));
    }

    render() {
        const { loading, error } = this.state;

        return (
            <div className="second-factor">
                <div className="page-title">Second Factor</div>
                <SecondFactorForm
                    validate={() => this.setState({ error: null })}
                    onSubmit={this.handleAuthentication}
                    error={error}
                    loading={loading}
                />
            </div>
        )
    }
}

export default withRouter(SecondFactor);