import React from 'react';
import { Form, Input as AntdInput } from 'antd';
import { Field } from 'formik';

const Input = ({ name, label, validate, suffix, required, className, ...props }) => (
    <Field name={ name } validate={ validate }>
        {({ field: { value, onChange, onBlur }, form: { errors, touched, submitCount } }) => {
            const errorsToShow = { ...props.errors, ...errors };
            return (
                <Form.Item label={ label } className={className}>
                    <AntdInput
                        name={ name }
                        value={ value }
                        onChange={ onChange }
                        onBlur={ onBlur }
                        suffix = { suffix }
                        { ...props }
                    />
                    <div className="form-error">{ (submitCount || touched[name]) && errorsToShow && errorsToShow[name] }</div>
                </Form.Item>
            )
        }}
    </Field>
);

export default Input;