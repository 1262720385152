import React from 'react';
import axios from "axios";
import {Spin} from "antd";
import {withRouter} from "react-router";
import {AuthService} from "../../authService";
import {SecondFactorForm} from "../../components/secondFactorForm";



class SetupSecondFactor extends React.Component {

    state = {

    };

    componentDidMount() {
        axios.get('/users/setup-second-factor').then(({data}) => {
            this.setState({secondFactorInfo: data})
        })
    }

    handleAuthentication = ({code}) => {
        this.setState({ loading: true });
        axios.post('/users/setup-second-factor', { secret: this.state.secondFactorInfo.secret, code })
            .then(({data}) => AuthService.SetupFullTokens(data, this.props.history))
            .catch(({ response: { data } }) => this.setState({ error: data, loading: false }));
    }

    render() {
        const { secondFactorInfo, loading, error } = this.state;
        return (
            <div className="setup-second-factor">
                { secondFactorInfo
                    ? <div className="setup-second-factor-page">
                        <div className="page-title">Setup Second Factor</div>
                        <div className="setup-second-factor-page-qr">
                            <img src={secondFactorInfo.qr} alt="qr-code"/>
                        </div>

                        <SecondFactorForm
                            validate={() => this.setState({ error: null })}
                            onSubmit={this.handleAuthentication}
                            error={error}
                            loading={loading}
                        />
                    </div>
                    : <Spin/> }
            </div>
        )
    }
}

export default withRouter(SetupSecondFactor);