import React from "react";
import Input from "./input";
import {HighlightOutlined} from "@ant-design/icons";
import {Button} from "antd";
import Form from "./form";
import * as Yup from "yup";

const AuthCodeSchema = Yup.object().shape({
    code: Yup.string().required('Code is Required'),
});

export const SecondFactorForm = ({onSubmit, validate, error, loading}) => {
    return (
        <div className="second-factor-code-form">
            <Form
                validate={ validate }
                onSubmit={ onSubmit }
                validationSchema={ AuthCodeSchema }
                initialValues={ {code: ''} }
            >
                <Input
                    name="code"
                    placeholder="Authentication Code"
                    prefix={ <HighlightOutlined /> }
                    errors={ { code: error } }
                />
                <Button
                    htmlType="submit"
                    type="primary"
                    className="second-factor-code-form-button"
                    loading={ loading }
                >
                    Send
                </Button>
            </Form>
        </div>
    )
};