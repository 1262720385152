import React from 'react';
import { Formik } from 'formik';
import { Form as AntdForm } from 'antd';

export function Form({ children, initialValues, onSubmit, layoutOptions, layout, validationSchema, ...props }) {
    return (
        <div className="form">
            <Formik
                initialValues={ initialValues }
                onSubmit={ onSubmit }
                validationSchema={ validationSchema }
                { ...props }
            >
                {({ handleSubmit }) => (
                    <AntdForm onFinish={ handleSubmit } layout={ layout } { ...layoutOptions } >
                        { children }
                    </AntdForm>
                )}
            </Formik>
        </div>
    );
}

Form.Item = AntdForm.Item;

export default Form;