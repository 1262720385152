import React from "react";
import moment from "moment";
import {Button, Popconfirm, Space} from "antd";
import {sorter} from "../../utils";

export const AssessmentsColumns = (onDownload, onDelete) => ([
    {
        title: 'Unique Code',
        dataIndex: 'code',
        key: 'code',
    },
    {
        title: 'Sex',
        dataIndex: 'sex',
        key: 'sex',
        sorter: sorter(a => a.sex),
    },
    {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
        sorter: sorter(a => a.age),
    },
    {
        title: 'Years in Education',
        dataIndex: 'years_in_education',
        key: 'years_in_education',
        sorter: sorter(a => a.years_in_education),
    },
    {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
        sorter: sorter(a => a.city),
    },
    {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        sorter: sorter(a => a.country),
    },
    {
        title: 'Received at',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: sorter(a => new Date(a.created_at).getTime()),
        render: (text, record) => <span>
            {  moment(text).format('DD/MM/YYYY HH:mm:ss') }
        </span>
    },
    {
        title: 'Is GENFI participant?',
        dataIndex: 'is_genfi_participant',
        key: 'is_genfi_participant',
        sorter: sorter(a => a.is_genfi_participant)
    },
    {
        title: 'GENFI Sync status',
        dataIndex: 'genfi_sync_status',
        key: 'genfi_sync_status',
        sorter: sorter(a => a.genfi_sync_status)
    },
    {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
            <Space size="middle">
                <Button type="link" onClick={() => onDownload(record.id, record.code)}>Download</Button>
                <Popconfirm placement="top" onConfirm={() => onDelete(record.id, record.code)} title="Are you sure to delete assessment results?">
                    <Button danger type="link" >Delete</Button>
                </Popconfirm>
            </Space>
        ),
    },
]);
