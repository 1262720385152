import React from 'react';
import Header from './header';
import Footer from './footer';

const ApplicationLayout = (props) => {
    const { children } = props;

    return (
        <div className="ignite-content">
            <Header/>
            <div className="main">
                <div className="main-wrapper">{children}</div>
            </div>
            <Footer/>
        </div>
    );
};

export default ApplicationLayout;