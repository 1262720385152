import axios from 'axios';
import { AuthService }  from './authService';

export const setAuthInterceptor = (history) => {
    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        if (error.response.status === 401) {
            console.log('info', 'auth interceptor caught 401 error');
            AuthService.SignOut(history);
        }
        return Promise.reject(error);
    });
};
