import React from 'react';
import './App.scss';
import Assessments from "./pages/assessments";
import {Route, Switch, withRouter} from 'react-router';
import ApplicationLayout from './components/applicationLayout';
import {ROUTES} from "./consts";
import SetupSecondFactor from "./pages/setupSecondFactor";
import SecondFactor from "./pages/secondFactor";
import Login from "./pages/login";
import {setAuthInterceptor} from "./authInterceptor";

class App extends React.Component {
    componentWillMount() {
        setAuthInterceptor(this.props.history);

        const accessTokenPayload = localStorage.getItem('accessTokenPayload') && JSON.parse(localStorage.getItem('accessTokenPayload'));
        if (!accessTokenPayload)
            this.props.history.push(ROUTES.LOGIN);
        else if (accessTokenPayload && !accessTokenPayload.configuredSecondFactor)
            this.props.history.push(ROUTES.SETUP_SECOND_FACTOR);
        else if (accessTokenPayload && !accessTokenPayload.hasSecondFactor && accessTokenPayload.configuredSecondFactor)
            this.props.history.push(ROUTES.SECOND_FACTOR);
        else if (accessTokenPayload && accessTokenPayload.hasSecondFactor)
            this.props.history.push(ROUTES.ASSESSMENTS);
    }

    render() {

        return (
            <div className="App">
                <Switch>
                    <Route exact component={ Login } path={ROUTES.LOGIN}/>
                    <ApplicationLayout>
                        <Switch>
                            <Route exact component={ SetupSecondFactor } path={ROUTES.SETUP_SECOND_FACTOR}/>
                            <Route exact component={ SecondFactor } path={ROUTES.SECOND_FACTOR}/>
                            <Route exact component={ Assessments } path={ROUTES.ASSESSMENTS}/>
                        </Switch>
                    </ApplicationLayout>
                </Switch>
            </div>
        );
    }
}

export default withRouter(App);
