import axios from 'axios';
import jwt from 'jsonwebtoken';
import {ROUTES} from "./consts";

export class AuthService {

    static SignIn(login, password, history) {
        return axios.post(`/users`, { login, password })
            .then(({ data }) => {
                if (data) {
                    const tokenPayload = jwt.decode(data)

                    localStorage.setItem('accessToken', data);
                    localStorage.setItem('accessTokenPayload', JSON.stringify(tokenPayload));
                    AuthService.setAuthorizationHeader(data);
                    !tokenPayload.configuredSecondFactor
                        ? history.push(ROUTES.SETUP_SECOND_FACTOR)
                        : history.push(ROUTES.SECOND_FACTOR);
                }
                return data;
            })
    }

    static SetupFullTokens({ accessToken, refreshToken }, history) {

        const accessTokenPayload = jwt.decode(accessToken);

        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('accessTokenPayload', JSON.stringify(accessTokenPayload));
        AuthService.setAuthorizationHeader(accessToken);
        history.push(ROUTES.ASSESSMENTS);
    }

    static SignOut(history) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessTokenPayload');
        history.push(ROUTES.LOGIN);
    }

    static setAuthorizationHeader(token) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }
}