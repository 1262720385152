import React from 'react';
import {AuthService} from "../authService";
import {LogoutOutlined} from "@ant-design/icons";
import {Tooltip} from "antd";
import {withRouter} from "react-router";

const getUserName = () => {
    const accessTokenPayload = localStorage.getItem('accessTokenPayload') && JSON.parse(localStorage.getItem('accessTokenPayload'));
    return accessTokenPayload ? accessTokenPayload.displayName : '';
}

const logoStyles = {
    background: `transparent url(/ignite-logo.png) no-repeat center`,
    backgroundSize: '100%',
};

const Header = (props) => {
    return (
        <div className="header">
            <div className="header-wrapper">
                <div>
                    <div className="header-label">
                        <div className="header-label-logo" style={ logoStyles }/>
                        <div className="header-label-name">Ignite App</div>
                    </div>
                </div>
                <div className="header-logout">
                    <div className="header-username">{ getUserName() }</div>
                    <Tooltip title="Logout" placement="left">
                        <LogoutOutlined  className="header-logout-button" onClick={ () => AuthService.SignOut(props.history) }/>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
}

export default withRouter(Header);