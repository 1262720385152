import React from 'react';
import Form from '../../components/form';
import Input from '../../components/input';
import { withRouter } from 'react-router';
import { AuthService } from '../../authService';
import * as Yup from 'yup';
import {Button} from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const SignInSchema = Yup.object().shape({
    username: Yup.string().required('Username is Required'),
    password: Yup.string().required('Password is Required'),
});

const logoStyles = {
    background: `white url(/ignite-logo.png) no-repeat center`,
    backgroundSize: '100%',
};

class Login extends React.Component {

    state = {
        error: null,
    };

    handleAuthentication = ({ username, password }) => {
        this.setState({ loading: true });
        AuthService.SignIn(username, password, this.props.history)
            .catch(({ response: { data } }) => this.setState({ error: data, loading: false }));
    };

    render() {
        const { loading, error } = this.state;

        return (
            <div className="login">
                <div className="login-page">
                    <div className="login-page-logo-wrapper">
                        <div className="login-page-logo" style={ logoStyles }/>
                        <div className="login-page-label">Ignite App</div>
                    </div>
                    <Form
                        validate={() => this.setState({ error: null })}
                        onSubmit={ this.handleAuthentication }
                        className="login-page-form"
                        validationSchema={ SignInSchema }
                        initialValues={ { username: '', password: '' } }
                    >
                        <Input
                            name="username"
                            placeholder="Username"
                            prefix={ <UserOutlined /> }
                        />
                        <Input
                            name="password"
                            placeholder="Password"
                            prefix={ <LockOutlined /> }
                            type="password"
                            errors={ { password: error } }
                        />
                        <Button
                            htmlType="submit"
                            type="primary"
                            className="login-page-button"
                            loading={ loading }
                        >
                            Sign In
                        </Button>
                    </Form>
                </div>
            </div>
        );
    }
}

export default withRouter(Login);