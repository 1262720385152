import React from 'react';

const logoStyles = {
    background: `transparent url(/ignite-logo.png) no-repeat center`,
    backgroundSize: '100%',
};

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-wrapper">
                Powered by
                <div style={logoStyles} className="footer-image"/>
            </div>
        </div>
    );
}

export default Footer;